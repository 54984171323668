<template>
<div>
		<div class='row'>
			<h3>Fee Waiver Dashboard</h3>

			<table class="table table-striped table-bordered">
				<thead>
					<tr>
						<th>EMAIL</th>
						<th>DATE INVITED</th>
						<th>PROGRESS</th>
						<th>VIDEO</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="waiver in fee_waivers" v-bind:key="waiver.invite_time">
						<td>
							<div v-if="waiver.sharee_email">{{waiver.sharee_email}}</div>
							<div v-else>
								<button class="btn btn-default" style="color:#e96724;" v-on:click="inviteStudentModal(waiver)">INVITE STUDENT</button>
							</div>
						</td>
						<td>{{dateFormat(waiver.invite_time)}}</td>
						<td>{{waiver.status}}</td>
						<td>
							<div v-if="waiver.ivVideo" class='centerLinks' v-on:click.prevent="linkTo(waiver.ivVideo)">
								<a>Watch</a> 
								<br /> 
								<img width='40px' :src="staticaddr + '/s/img/organization/VideoCamera.png'"> 
								<br />
							</div>
						</td>
					</tr>
				</tbody>
			</table>

			<div class="row" v-if="feeWaiversUsedUp">
				<div class="col-sm-4 col-sm-offset-4 text-center">
					<h4 style="font-style:italic;color:666;">"Hey InitialView! We need more Fee Waivers!"</h4>
				</div>
				<div class="col-sm-4 col-sm-offset-4 text-center">
					<div class="btn btn-default" style="color:#e96724;" v-on:click="requestMoreFeeWaivers()">
						REQUEST MORE FEE WAIVERS
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-sm-4 col-sm-offset-4 text-center" v-if="feewaiverRequestStatus == 'granted'">
					<div>Extra fee waivers granted!</div>
				</div>
				<div class="col-sm-4 col-sm-offset-4 text-center" v-if="feewaiverRequestStatus == 'pending'">
					<div>We've received your request and will get back to you shortly!</div>
				</div>
			</div>

		</div>
        <div class="modal fade" id="feeWaiverInvite" tabindex="-1" role="dialog"  aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Send a Student a Fee Waiver</h4>
                    </div>
                    <div class="modal-body">
                    <div class="row">
                        <div class="col-sm-12 form-horizontal">
                        <label>To</label>
                        <input class="form-control" v-model="shareEmail.toEmail" placeholder="student@example.com">
                        </div>
                    </div>  
                    <div class="row">
                        <div class="col-sm-12 form-horizontal">        
                        <label>Message</label>
                        <div style="border:1px solid #ccc;padding:5px;">
                        <textarea rows="12" class="form-control" v-model="shareEmail.text"></textarea>
                        <div style="padding: 5px 10px;border: 1px solid #999;margin-top: 5px;">
                            <div>Click here to book your interview and redeem your fee waiver coupon: [link]</div>
                        </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div class="modal-footer">
                        <div class="pull-right" v-show="confirmCancel == true">
                        <span>
                            Are you sure you want to cancel?
                        </span>
                        <button type="button" class="btn btn-danger" aria-label="Close" style="margin-right:10px;margin-left:10px" v-on:click="closeInviteStudentModal()"><span aria-hidden="true">Yes</span></button>
                        <button type="button" class="btn btn-warning" aria-label="Close" v-on:click="confirmCancel = false"><span aria-hidden="true">No</span></button>
                        </div>
                        <div class="pull-right" v-show="confirmCancel == false" >
                        <button class="btn pull-right btn-warning" style="margin-left:10px;" v-on:click="confirmCancel = true">Cancel</button>
                        <button class="btn pull-right btn-success" v-bind:class="{'disabled': !shareEmail.toEmail || !shareEmail.text}" v-on:click="sendFeeWaiver()">Send</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

	</div>
    </template>

<script>

	import moment from 'moment-timezone';
	import {
        ivAlertMixin,
        ivSpinnerMixin,
		ivModalMixin,
	} from "../mixins/base-components-pack.js";
	
	export default {
		mixins: [ivModalMixin, ivAlertMixin, ivSpinnerMixin],
		props: [
			"fee_waivers",
			"feewaiverRequestStatus",
		],
		watch: {
			fee_waivers: function () {
				this.closeInviteStudentModal();
			}
		},
		data: function() {
			return {
				shareEmail: {
					toEmail: "",
					text: "",
				},
				confirmCancel: false,
			}
		},

		computed: {
			feeWaiversUsedUp: function () {
				return this.fee_waivers.filter((fw) => {
					return fw.status == "";
				}).length == 0;
			}
		},

		methods: {
			dateFormat: function (date) {
				return date ? moment(date).format("YYYY-MM-DD") : "";
			},
			inviteStudentModal: function (waiver) {
				var that = this;
				this.$set(this, "shareEmail", {
					toEmail: "",
					text: "Dear Student,\n\nWe would like to invite you to conduct an InitialView interview as a supplement to your application, and as a result, and we are providing you with a fee waiver coupon code.  To book your interview, click the link below.\n\nAn InitialView interview will allow you, in a live interview format, to demonstrate important communication skills while also sharing your background and goals in a live interview format. InitialView is not a test: It does not score or rate applicants, and its materials, which include a short writing sample along with an interview, will be reviewed by our office as part of your overall application.\n\nInitialView is available worldwide online. Feel free to email InitialView at contact@initialview.com if you have any questions. \n\nWarm regards,\n\n" + that.orgName + " Admissions Office",
				});
				that.modalWaiver = waiver;
				// open an invitation modal
				that.openModal("feeWaiverInvite");
			},

			closeInviteStudentModal: function () {
				var that = this;
				this.modalWaiver = null;
				this.confirmCancel = false;
				this.$set(this, "shareEmail", {
					toEmail: "",
					text: "Dear Student,\n\nWe would like to invite you to conduct an InitialView interview as a supplement to your application, and as a result, and we are providing you with a fee waiver coupon code.  To book your interview, click the link below.\n\nAn InitialView interview will allow you, in a live interview format, to demonstrate important communication skills while also sharing your background and goals in a live interview format. InitialView is not a test: It does not score or rate applicants, and its materials, which include a short writing sample along with an interview, will be reviewed by our office as part of your overall application.\n\nInitialView is available worldwide online. Feel free to email InitialView at contact@initialview.com if you have any questions. \n\nWarm regards,\n\n" + that.orgName + " Admissions Office",
				});
				that.closeModal("feeWaiverInvite");
			},

			sendFeeWaiver: function () {
				this.$emit("sendFeeWaiver", {couponCode: this.modalWaiver.couponCode, shareEmail: this.shareEmail});
			},

			requestMoreFeeWaivers: function () {
				this.$emit("requestMoreFeeWaivers");
			}

		}

	}
</script>