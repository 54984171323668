<template>
<div>
	<div v-show="!hsprofile">
		<div class='row'>
			<div class='col-md-12'>
				<h3>High School Profile Search</h3>
			</div> 
		</div>
		<div class='container' id='list-view' style="min-width:1394px;top:151px">
			<div>
				<table class="table table-bordered table-striped">
					<thead>
						<!-- be able to export the whole regular excel sheet, in case there is desire for a whole column of phone numbers or something -->
						<tr>
							<th style="width:50%">
								<div class="text-center">
									School Name
									<span v-if="orderByColumnDept == 'name_en'" v-on:click="sortOrderDept = sortOrderDept * -1">
										<i class="glyphicon" v-bind:class="{'glyphicon-menu-up': sortOrderDept > 0, 'glyphicon-menu-down': sortOrderDept < 0}"></i>
									</span>
								</div>
								<input type="text" class="form-control" v-model="filterObjectDept.name">
							</th>
							<th style="width:30%">
								<div class="text-center">
									Country, Province, City
									<span v-if="orderByColumnDept == 'location'" v-on:click="sortOrderDept = sortOrderDept * -1">
										<i class="glyphicon" v-bind:class="{'glyphicon-menu-up': sortOrderDept > 0, 'glyphicon-menu-down': sortOrderDept < 0}"></i>
									</span>
								</div>
								<input type="text" class="form-control" v-model="filterObjectDept.location">
							</th>
							<th style="width:20%">
								<div class="text-center">
									CEEB Code
									<span v-if="orderByColumnDept == 'ceeb'" v-on:click="sortOrderDept = sortOrderDept * -1">
										<i class="glyphicon" v-bind:class="{'glyphicon-menu-up': sortOrderDept > 0, 'glyphicon-menu-down': sortOrderDept < 0}"></i>
									</span>
								</div>
								<input type="text" class="form-control" v-model="filterObjectDept.ceeb">
							</th>
						</tr>
					</thead>
				</table>
			</div>
			<div style="height:100%;overflow:auto;" v-on:scroll="handleScroll" id="scrollableSearch">
				<table class="table table-bordered table-striped" v-bind:style="itemsStyle">
					<tbody>
						<!-- details view will allow for editing -->
						<tr v-for="dept in visibleDepts" v-bind:key="dept.unique_id" v-on:click="selectDept(dept)" style="cursor:pointer;">
							<td style="width:50%">
								<div style="height:19px">
									{{dept.name_en}}
								</div>
								<hr style="margin-top:5px; margin-bottom:5px;">
								<div style="height:19px">
									{{dept.name_alt}}
								</div>
							</td>
							<td style="width:30%">
								{{dept.country}}, {{dept.province}}, {{dept.city}}
							</td>
							<td style="width:20%">
								{{dept.ceeb}}
							</td>                        
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>

	<div class="row tab-area-row" v-if="hsprofile" style="padding:0">
		<div v-on:click="hsprofile=null" style="padding:5px 10px;">
			<span class='glyphicon glyphicon-arrow-left'></span><a style="font-size: 1.2em; padding: 0px 10px;cursor:pointer">Back</a>
		</div>
		<div class="col-md-12">
			<HSProfile :hsprofile="hsprofile" :curLength="hsprofile.curricula.length" :iveeData="{user: 'Example, Student', curriculum: 'Example Curriculum'}" @load-other-profile="fetchHSProfile"/>
		</div>
	</div>
</div>
</template>

<script>

import Vue from 'vue';
import {
	ivSpinnerMixin,
	ivAlertMixin,
} from "@/mixins/base-components-pack.js";
import moment from 'moment-timezone';
import EmailStudent from "@/components/EmailStudent.vue";
import HSProfile from "@/components/HSProfile.vue";
import ivbus from "@/utils/ivbus.js";

import apiClient from "../utils/apiClient.js";

import env from "../utils/env.js";

export default {
	props: [
		'ameliaaddr',
		'settingsSlateURL',
		"org_cycles",
		"orgTypes",
		"value", // filter_cycle
	],

	mixins: [ivSpinnerMixin],

	components: {
		HSProfile
	},

data() {
		return {
			hsprofile: null,
			ivHSProfile: null,
			departments: [],
            filterObjectDept: {
                location: "",
                name: "",
                ceeb: "",
                published: "",
                unique_id: ""
            },
            orderByColumnDept: "name_en",
			sortOrderDept: 1,

			sortOrder: 1, //just to keep it happy...

			$_startIndex: 0,
			itemContainerStyle: null,
			itemsStyle: null,
			scroll: {
				top: 0,
				bottom: 20,
			},
		}
	},

	mounted () {
		var that = this;

		that.fetchDepartmentList();

	},

	computed: {
		filteredDept: function () {
			var that = this;
			return that.departments.filter(function (department) {
				var match = true;
				if (match && that.filterObjectDept.published) {
					match = department.published && department.published.toLowerCase().indexOf(that.filterObjectDept.published.toLowerCase()) !== -1;
				}
				if (match && that.filterObjectDept.unique_id) {
					match = department.unique_id && department.unique_id.toLowerCase().indexOf(that.filterObjectDept.unique_id.toLowerCase()) !== -1;
				}
				if (match && that.filterObjectDept.location) {
					match = (department.country && department.country.toLowerCase().indexOf(that.filterObjectDept.location.toLowerCase()) !== -1) ||
						(department.province && department.province.toLowerCase().indexOf(that.filterObjectDept.location.toLowerCase()) !== -1) ||
						(department.city && department.city.toLowerCase().indexOf(that.filterObjectDept.location.toLowerCase()) !== -1);
				}
				if (match && that.filterObjectDept.name) {
					match = (department.name_en && department.name_en.toLowerCase().indexOf(that.filterObjectDept.name.toLowerCase()) !== -1) ||
						(department.name_cn && department.name_cn.toLowerCase().indexOf(that.filterObjectDept.name.toLowerCase()) !== -1);
				}
				if (match && that.filterObjectDept.ceeb) {
					match = department.ceeb && department.ceeb.toLowerCase().indexOf(that.filterObjectDept.ceeb.toLowerCase()) !== -1;
				}
				return match;
			});
		},
		sortedDept: function () {
			var that = this;
			return this.filteredDept.slice().sort(function (a, b) {
				var aVal = a[that.orderByColumnDept] || "";
				var bVal = b[that.orderByColumnDept] || "";
				var so = that.sortOrderDept;
				return (aVal > bVal) ? so : (aVal < bVal) ? (-1 * so) : 0;
			});
		},

		visibleDepts: function () {
			var _this2 = this;

			var l = _this2.sortedDept.length;
			var itemHeight = 66;
			var containerHeight = void 0,
				offsetTop = void 0;

			var scroll = this.scroll;

			if (scroll) {
				console.log("in VisibleDepts");
				console.log("scroll.top:", scroll.top);
				console.log("scroll.bottom", scroll.bottom);
				var startIndex = -1;
				var endIndex = -1;

				var buffer = 200;
				var poolSize = 2000;
				var scrollTop = ~~(scroll.top / poolSize) * poolSize - buffer;
				var scrollBottom = Math.ceil(scroll.bottom / poolSize) * poolSize + buffer;

				// if ((scrollTop === _this2.$_oldScrollTop && scrollBottom === _this2.$_oldScrollBottom) || _this2.$_skip) {
				//   _this2.$_skip = false;
				//   return;
				// } else {
				_this2.$_oldScrollTop = scrollTop;
				_this2.$_oldScrollBottom = scrollBottom;
				// }

				// Fixed height mode
				startIndex = ~~(scrollTop / itemHeight);
				endIndex = Math.ceil(scrollBottom / itemHeight);

				// Bounds
				startIndex < 0 && (startIndex = 0);
				endIndex > l && (endIndex = l);

				offsetTop = startIndex * itemHeight;
				containerHeight = l * itemHeight;

				if (_this2.$_startIndex !== startIndex || _this2.$_endIndex !== endIndex || _this2.$_offsetTop !== offsetTop || _this2.$_height !== containerHeight || _this2.$_length !== l) {
					_this2.keysEnabled = !(startIndex > _this2.$_endIndex || endIndex < _this2.$_startIndex);

					Vue.set(_this2, "itemContainerStyle", {
						height: containerHeight + 'px'
					});
					Vue.set(_this2, "itemsStyle", {
						marginTop: offsetTop + 'px'
					});

					_this2.$_startIndex = startIndex;
					_this2.$_endIndex = endIndex;
					_this2.$_length = l;
					_this2.$_offsetTop = offsetTop;
					_this2.$_height = containerHeight;

				}
			}

			window.setTimeout(function () {
				console.log("tooltip timeout")
				$('[data-toggle="tooltip"].review-score').tooltip();
			});

			return this.sortedDept.slice(_this2.$_startIndex, _this2.$_endIndex);
		}
	},

	methods: {

		handleScroll: function () {
			// console.log("SCROLL IS");
			var el = $("#scrollableSearch")[0];
			var scroll = {
				top: el.scrollTop,
				bottom: el.scrollTop + el.clientHeight
			};

			if (scroll.bottom >= 0 && scroll.top <= scroll.bottom) {
				Vue.set(this, "scroll", scroll);
				// console.log(scroll);
			} else {
				Vue.set(this, "scroll", null);
			}
		},

		fetchHSProfile: function (uid) {
			var that = this;
			var spinId = that.spinStart();
			apiClient.get("/api/hsprofile/department/?unique_id=" + uid).then(function (response) {
				that.spinStop(spinId);
				if (response.data.status == "200") {
					if (response.data.dept.hs_name) {
						that.$set(that, 'hsprofile', response.data.dept);
						ivbus.setFlag("hsprofile", response.data.dept);
						that.$set(that, 'ivHSProfile', "exists");
					}
				}
			}).catch(function (error) {
				console.log(error);
			});
		},

		selectDept: function (dept) {
			// TODO: maybe some warnings if the existing selectedDept is dirty?
			// this.fetchDepartment(dept);
			// this.closeModal("high-schools");
			var uid = dept.unique_id;
			this.fetchHSProfile(uid);

		},

		fetchDepartmentList: function () {
			var that = this;
			var spinId = this.spinStart();

			apiClient.get("/api/hsprofile/department_list_amelia/").then(function (response) {
				that.spinStop(spinId);
				console.log("fetchDepartmentList method called");
				that.$set(that, 'departments', response.data.dept_list);
			}).catch(function (e) {
				console.log("Error in fetchDepartmentList method")
				that.spinStop(spinId);
			});
		},


	}

}
	
</script>

<style lang="scss">

.modelInput {
  border: solid 2px #6a605f;
  border-radius: 15px;
  margin-bottom: 25px;
  padding: 15px;
}

.btn-submit-message {
  color: white;
  background-color: #6a605f;
  padding: 10px 40px;
  border-radius: 14px;
  font-size: 124%;
  font-weight: lighter;
}

</style>