<template>
  <div>
    <div class="container" style="width: 400px" v-if="apiDown">
      <center>
        <h3>System upgrade in progress, please try again in 5 minutes.</h3>
      </center>
    </div>
    <div v-if="!apiDown">
      <div class='row'>
        <div class='col-md-12'>
          <h3>Settings</h3>
        </div>
      </div>

      <div class="row" v-if="orgTypeSA">
        <div class="col-md-8 col-md-offset-2">
          <h4>Selfie Assessment Questions</h4>
          <div class="form-group" v-for="(q, ind) in questions" v-bind:key="ind">
            <div class="row">
              <div class="col-md-11">
                <p class="modalLabel" style="margin-left: 15px;margin-top:0px;">Question {{ q.index }}</p>
                <textarea class="modelInput form-control" style="margin-bottom:0px; font-size:125%;" v-model="q.text"
                  rows="2" maxlength="150"></textarea>
                <span class="pull-right" style="color:#6a605f; margin-right: 15px; font-size: 80%;">{{ 150 -
                  q.text.length }}</span>
              </div>
              <div class="col-md-1">
                <div class="pull-right" style="margin-top:65px;">
                  <span class="modelInput" v-on:click="deleteQuestion(ind)" style="padding:10px;">
                    <i class="glyphicon glyphicon-minus"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-offset-11 col-md-1">
              <div class="pull-right" style="margin-top:15px;">
                <span class="modelInput" v-on:click="addQuestion()" style="padding:10px;">
                  <i class="glyphicon glyphicon-plus"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-offset-2 col-md-8" style="margin-bottom:25px;">
              <span class="btn-submit-message" v-on:click="saveQuestions()" style="margin-left: 40px;">SAVE</span>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <h4>Selfie Assessment Invitations</h4>
          <EmailStudent v-bind:student_firstName="student_firstName" v-bind:student_lastName="student_lastName"
            v-bind:student_email="student_email" v-bind:setup="emailStudentSetup"></EmailStudent>
        </div>
      </div>

      <div class='row'>
        <div class="col-md-12">
          <h4>Slate</h4>
          <div><a href="/integration-docs" target="_blank" rel="opener">Click here for documentation</a> about the data
            fields that can be retrieved through the Slate integration.</div>
          <div class="well">{{ settingsSlateURL }}</div>
        </div>
      </div>
      <div class='row'>
        <div class="col-sm-12">
          <h4>Change Password</h4>
        </div>
        <div class="col-xs-4">
          <a :href="env.serverAddr + '/password/change/'" target="_blank">Click here to change your password.</a>
        </div>
      </div>
      <div class='row'>
        <div class="col-md-12">
          <h4>Cycle</h4>
        </div>
        <div class="col-md-6">
          <form class="form-horizontal">
            <div class="form-group col-md-12">
              <!-- this col pulls the margin in so it's not misaligned with the other Settings -->
              <label>Choose which cycle to filter on. Contact us if you would like to customize the date range of each
                cycle.</label>
              <select data-cy="cycle" class="form-control" v-bind:value="value" v-on:change="startTimer($event)">
                <option v-bind:value="cycle.cycle_id" v-bind:data-cy="'cycle-' + cycle.cycle_id"
                  v-for="cycle in org_cycles" v-bind:key="cycle.cycle_id">
                  <!-- <span v-show="cycle.cycle_id == active_cycle.cycle_id">(current {{cycle.cycle_id}} {{active_cycle.cycle_id}}) </span> -->
                  {{ cycle.label }} ({{ dateFormat(cycle.start_date) }} to {{ dateFormat(cycle.end_date) }})
                </option>
              </select>
            </div>
          </form>
        </div>
      </div>
      <div class='row'>
        <div class="col-sm-12">
          <h4>Video Playback</h4>
        </div>
        <div class="col-sm-12">
          <h5>Would you like to block the video from Glimpses and listen to audio only?</h5>
        </div>
        <div class="col-xs-12">
          <label>
            <input data-cy="playback-true" type="radio" name="playbackRadios" id="playbackRadios1" value="true"
              v-model="settingsPlayback.block_video">
            Block video and play audio only
          </label>
        </div>
        <div class="col-xs-12">
          <label>
            <input data-cy="playback-false" type="radio" name="playbackRadios" id="playbackRadios1" value="false"
              v-model="settingsPlayback.block_video">
            Play both video and audio
          </label>
        </div>
      </div>
      <div class='row'>
        <div class="col-xs-6">
          <br />
          <h4>Email Notices about Interviews</h4>
          <div class="radio">
            <label>
              <input data-cy="interviewAlert-now" type="radio" name="optionsRadios" id="optionsRadios1" value="Now"
                v-model="settingsEmailInterview">
              Immediately <small>(each time my school receives a new interview)</small>
            </label>
          </div>
          <div class="radio">
            <label>
              <input data-cy="interviewAlert-daily" type="radio" name="optionsRadios" id="optionsRadios2" value="Daily"
                v-model="settingsEmailInterview">
              Daily <small>(receive a daily email on days when your school receives an interview)</small>
            </label>
          </div>
          <div class="radio">
            <label>
              <input data-cy="interviewAlert-weekly" type="radio" name="optionsRadios" id="optionsRadios3"
                value="Weekly" v-model="settingsEmailInterview">
              Weekly <small>(receive a weekly email for weeks when your school receives an interview) (recommended in
                most cases)</small>
            </label>
          </div>
          <div class="radio">
            <label>
              <input data-cy="interviewAlert-never" type="radio" name="optionsRadios" id="optionsRadios4" value="Never"
                v-model="settingsEmailInterview">
              Never
            </label>
          </div>

          <br />
          <h4>Email Notices about Ratings of Interviews:</h4>

          <div class="radio">
            <label>
              <input data-cy="reviewAlert-mine" type="radio" name="ratingEmailRadios" id="ratingEmailRadios1"
                value="Mine" v-model="settingsEmailReview">
              My Ratings <small>(receive an email each time I rate an interview)</small>
            </label>
          </div>
          <div class="radio">
            <label>
              <input data-cy="reviewAlert-all" type="radio" name="ratingEmailRadios" id="ratingEmailRadios2" value="All"
                v-model="settingsEmailReview">
              All Ratings <small>(receive an email each time anyone in your organization rates an interview)</small>
            </label>
          </div>
          <div class="radio">
            <label>
              <input data-cy="reviewAlert-never" type="radio" name="ratingEmailRadios" id="ratingEmailRadios3"
                value="Never" v-model="settingsEmailReview">
              Never
            </label>
          </div>

        </div>
        <div class="col-xs-6">

          <br />
          <h4>Automatic Bedelia Messages</h4>
          <div>When a student sends your institution a new interview, automatically send them the following Bedelia
            message:</div>
          <div class="radio">
            <label style="width:100%">
              <input data-cy="autoMessage-true" type="radio" name="bedeliaAutorespondRadios"
                id="bedeliaAutorespondRadios1" value="Message" v-model="settingsBedeliaAutoresponse">
              <textarea type="text" class="form-control message-textarea" name="bedeliaAutorespondBody"
                placeholder="Message confirming receipt of interview"
                v-model="settingsBedeliaAutoresponseDict.send.Message.body" rows="3"
                v-on:click="selectBedeliaAutorespondRadio()"></textarea>
              And set the following Auto-Reply message:
              <textarea type="text" class="form-control message-textarea" name="bedeliaAutoReplyBody"
                placeholder="Auto-Reply if the student responds with a message"
                v-model="settingsBedeliaAutoresponseDict.send.Message.auto_reply" rows="3"
                v-on:click="selectBedeliaAutorespondRadio()"></textarea>
            </label>
          </div>
          <div class="radio">
            <label>
              <input data-cy="autoMessage-false" type="radio" name="bedeliaAutorespondRadios"
                id="bedeliaAutorespondRadios2" value="None" v-model="settingsBedeliaAutoresponse">
              Don't send an automatic Bedelia message
            </label>
          </div>
          <br />
          <h4>Email Notices about Bedelia Messages</h4>
          <div class="radio">
            <label>
              <input data-cy="messageAlert-now" type="radio" name="bedeliaMessageRadios" id="optionsRadios1" value="Now"
                v-model="settingsBedeliaMessage">
              Immediately <small>(each time my school receives a new Bedelia message)</small>
            </label>
          </div>
          <div class="radio">
            <label>
              <input data-cy="messageAlert-daily" type="radio" name="bedeliaMessageRadios" id="optionsRadios2"
                value="Daily" v-model="settingsBedeliaMessage">
              Daily <small>(receive a daily email on days when your school receives a Bedelia message) (recommended in
                most cases)</small>
            </label>
          </div>
          <div class="radio">
            <label>
              <input data-cy="messageAlert-weekly" type="radio" name="bedeliaMessageRadios" id="optionsRadios3"
                value="Weekly" v-model="settingsBedeliaMessage">
              Weekly <small>(receive a weekly email for weeks when your school receives a Bedelia message)</small>
            </label>
          </div>
          <div class="radio">
            <label>
              <input data-cy="messageAlert-never" type="radio" name="bedeliaMessageRadios" id="optionsRadios4"
                value="Never" v-model="settingsBedeliaMessage">
              Never
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <!-- <div class='col-xs-offset-2'> -->
          <div class="center">
            <button data-cy="settingsSubmit" type="submit" class="btn btn-primary"
              v-on:click.prevent="post_user_settings()">Save Settings</button>
          </div>
          <!-- </div> -->
        </div>
      </div>

    </div>

  </div>
</template>

<script>

import { mapState } from 'vuex'
import {
  ivSpinnerMixin,
  ivAlertMixin,
} from "@/mixins/base-components-pack.js";
import moment from 'moment-timezone';
import EmailStudent from "@/components/EmailStudent.vue";
import ivbus from "@/utils/ivbus.js";

import apiClient from "../utils/apiClient.js";

import env from "../utils/env.js";

export default {
  props: [
    'ameliaaddr',
    'settingsSlateURL',
    "org_cycles",
    "orgTypes",
    "value", // filter_cycle
  ],

  mixins: [ivSpinnerMixin],

  components: {
    EmailStudent
  },

  data() {
    return {
      // settings page

      env: env,

      settingsBedeliaMessage: "Never",
      settingsBedeliaAutoresponse: "None",

      settingsEmailSubscriptionDict: {
        "interview": {
          "Now": {
            "period": null,
            "alert_object": "interview.Send",
            "alert_every": true,
            "alert_every_org": false
          },
          "Daily": {
            "period": "1",
            "alert_object": "interview.Send",
            "alert_every": false,
            "alert_every_org": false
          },
          "Weekly": {
            "period": "7",
            "alert_object": "interview.Send",
            "alert_every": false,
            "alert_every_org": false
          },
          "Never": null
        },
        "review": {
          "Mine": {
            "period": null,
            "alert_object": "organization.Review",
            "alert_every": true,
            "alert_every_org": false
          },
          "All": {
            "period": null,
            "alert_object": "organization.Review",
            "alert_every": true,
            "alert_every_org": true
          },
          "Never": null
        }
      },

      settingsBedeliaSubscriptionDict: {
        messages: {
          Now: {
            period: null, // I think every minute we should see if there are messages that have still been unread for more than 1 minute (avoid 10 second situation)
            alert_object: "bedelia.Message",
            alert_every: true,
            alert_every_org: false,
          },
          Daily: {
            period: "1",
            alert_object: "bedelia.Message",
            alert_every: false,
            alert_every_org: false,
          },
          Weekly: {
            period: "7",
            alert_object: "bedelia.Message",
            alert_every: false,
            alert_every_org: false,
          },
          Never: null
        }
      },

      settingsBedeliaAutoresponseDict: {
        send: {
          Message: {
            body: "Thank you for sending us your InitialView materials!",
            auto_reply: "Hello! We’re away from our messaging system and may not be able to reply to you in a timely fashion. If you have a question that requires a response, we recommend you call or email us. Thank you!"
          },
          None: null,
        }
      },

      settingsPlayback: {
        block_video: false
      },

      settingsEmailInterview: "Never",
      settingsEmailReview: "Never",

      questions: [
        { index: 1, text: "" },
        { index: 2, text: "" },
        { index: 3, text: "" },
        { index: 4, text: "" }
      ],

      student_firstName: "",
      student_lastName: "",
      student_email: "",

      emailStudentSetupLoaded: false,
      emailStudentSetup: {},

      user: {},

    }
  },

  mounted() {
    var that = this;
    that.get_user_settings(); // does this need to wait for user_org_info?

    that.fetchQuestions();
    ivbus.getFlag("user").then(function (user) {
      that.$set(that, "user", user);
      that.$set(that, "emailStudentSetup", {
        context: "selfieAssessmentInvite",
        counselor_first_name: user.first_name,
        counselor_last_name: user.last_name,
        counselor_email: user.email,
        modalLabel: "send invites",
        sendToLabel:
          "send a Selfie Assessment invite to the following email address(es):",
        sendNumber: "multiple",
        subjectLabel: "subject:",
        messageLabel: "message:",
        modalLabelStyle: "plumText",
        inviteRotateStyle: "plumText",
        inviteRotatePlacement: "top: 0px !important",
        modalInputStyle: "plumBorder",
        btnStyle: "plumButton"
      });
      that.emailStudentSetupLoaded = true;
    });
  },

  computed: {
    orgTypeSA: function () {
      return this.orgTypes.filter(function (ot) { return ot.type == "org_facing_cadenza" }).length > 0;
    },
    ...mapState(['apiDown']),
  },

  methods: {

    dateFormat: function (date) {
      return date ? moment(date).format("YYYY-MM-DD") : "";
    },

    selectBedeliaAutorespondRadio: function () {
      this.settingsBedeliaAutoresponse = "Message";
    },

    startTimer: function ($event) {
      this.$emit('input', $event.target.value);
      this.$emit('startTimer');
    },

    get_user_settings: function () {
      var that = this;
      var spinId = this.spinStart();
      apiClient.get('/api/user/user_settings/get/').then(function (response) {
        that.spinStop(spinId);
        if (response.data.status == 200) {
          var i = 0;
          var subs = response.data.user_settings.user_subscriptions;
          console.log(subs);
          for (i = 0; i < subs.length; i++) {
            var s = subs[i];
            console.log(s);
            if (s.alert_object == "organization.Review") {
              if (s.alert_every == true) {
                that.$set(that, 'settingsEmailReview', 'Mine');
              } else if (s.alert_every_org == true) {
                that.$set(that, 'settingsEmailReview', 'All');
              }
            }
            if (s.alert_object == "interview.Send") {
              if (s.period == null) {
                that.$set(that, 'settingsEmailInterview', 'Now');
              } else if (s.period.charAt(0) == '1') {
                that.$set(that, 'settingsEmailInterview', 'Daily');
              } else if (s.period.charAt(0) == '7') {
                that.$set(that, 'settingsEmailInterview', 'Weekly');
              }
            }
            if (s.alert_object == "bedelia.Message") {
              if (s.period == null) {
                that.$set(that, 'settingsBedeliaMessage', 'Now');
              } else if (s.period.charAt(0) == '1') {
                that.$set(that, 'settingsBedeliaMessage', 'Daily');
              } else if (s.period.charAt(0) == '7') {
                that.$set(that, 'settingsBedeliaMessage', 'Weekly');
              }
            }
          }

          var autoresponses = response.data.user_settings.bedelia_autoresponses;
          for (i = 0; i < autoresponses.length; i++) {
            var r = autoresponses[i];
            // ok we do need some sort of action identifier
            that.$set(that, 'settingsBedeliaAutoresponse', 'Message');
            that.$set(that.settingsBedeliaAutoresponseDict.send, 'Message', r)
          }

          that.$set(that, "settingsPlayback", response.data.user_settings.video_playback);

        }
      }).catch(function (error) {
        that.spinStop(spinId);
      });

    },

    post_user_settings: function () {
      var that = this;
      var settings_info = {
        "user_settings": {
          "user_subscriptions": [],
          "bedelia_autoresponses": [],
          video_playback: this.settingsPlayback
        }
      };
      //add item to settings info by looking it up in dict for each radio group
      //interview
      var interviewSE = this.settingsEmailSubscriptionDict.interview[this.settingsEmailInterview];
      if (interviewSE != null) {
        settings_info.user_settings.user_subscriptions.push(interviewSE);
      }
      //review
      var reviewSE = this.settingsEmailSubscriptionDict.review[this.settingsEmailReview];
      if (reviewSE != null) {
        settings_info.user_settings.user_subscriptions.push(reviewSE);
      }
      // Bedelia
      var bedeliaSE = this.settingsBedeliaSubscriptionDict.messages[this.settingsBedeliaMessage];
      if (bedeliaSE != null) {
        settings_info.user_settings.user_subscriptions.push(bedeliaSE);
      }

      var bedeliaAR = this.settingsBedeliaAutoresponseDict.send[this.settingsBedeliaAutoresponse];
      if (bedeliaAR != null) {
        settings_info.user_settings.bedelia_autoresponses.push(bedeliaAR);
      }

      var spinId = this.spinStart();
      apiClient.post('/api/user/user_settings/submit/', settings_info).then(function (request) {
        that.spinStop(spinId);
        if (request.status == 200) {
          that.showMessage('Settings Saved!', 'alert-success', that.uiMessageWaitLong);
        } else {
          that.showMessage('Settings Failed', "alert-danger", that.uiMessageWaitLong);
        }
      }).catch(function () {
        that.spinStop(spinId);
      });

    },

    // Selfie assessment questions

    addQuestion: function () {
      var that = this;
      var index = that.questions.length + 1;
      that.questions.push({ index: index, text: "" });
    },

    deleteQuestion: function (index) {
      var that = this;
      that.questions.splice(index, 1);
      that.questions.forEach(function (q, ind) {
        q.index = ind + 1;
      });
      if (that.questions.length < 4) {
        for (var i = that.questions.length; i < 4; i++) {
          that.questions.push({ index: i + 1, text: "" });
        }
      }
    },

    fetchQuestions: function () {
      var that = this;
      var spinId = this.spinStart();
      apiClient
        .get("/api/user/amelia_sa_questions/")
        .then(function (response) {
          that.spinStop(spinId);
          that.$set(that, "questions", response.data.data);
          if (that.questions.length < 4) {
            for (var i = that.questions.length; i < 4; i++) {

            }
          }
        })
        .catch(function (error) {
          that.spinStop(spinId);
          console.log(error);
        });
    },

    numberQuestions: function () {
      var that = this;
      var numberquestions = 0;
      that.questions.forEach(function (q) {
        if (q["text"].length > 0) {
          numberquestions += 1;
        }
      });
      return numberquestions;
    },

    saveQuestions: function () {
      var that = this;
      that.questions.forEach(function (q, ind) {
        if (q.text == "") {
          that.questions.splice(ind, 1);
        }
      });
      that.questions.forEach(function (q, ind) {
        q.index = ind + 1;
      });
      apiClient
        .post("/api/user/save_amelia_sa_questions/", {
          counselor_questions: that.questions
        })
        .then(function (request) {
          if (request.data.status == 200) {
            that.showMessage("Questions saved!", "alert-success", 5000);
            if (that.questions.length < 4) {
              for (var i = that.questions.length; i < 4; i++) {
                that.questions.push({ index: i + 1, text: "" });
              }
            }
          } else {
            that.showMessage(
              "Error saving questions - please try again",
              "alert-danger",
              10000
            );
          }
        });
    },


  }

}

</script>

<style lang="scss">
.modelInput {
  border: solid 2px #6a605f;
  border-radius: 15px;
  margin-bottom: 25px;
  padding: 15px;
}

.btn-submit-message {
  color: white;
  background-color: #6a605f;
  padding: 10px 40px;
  border-radius: 14px;
  font-size: 124%;
  font-weight: lighter;
}
</style>